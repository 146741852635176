.configureButton {
    border: none;
    align-items: center;
    margin-left: 5.5vw;
    margin-bottom: 0.5vw;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
    border-radius: 12px;
    color: black;
    width: calc(20px + 5vw);
    height: calc(12px + 3vh);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: calc(8px + 0.6vh);
    cursor: pointer;
}

.configureList {
    border: none;
}

.configureHR {
    width: 15vw;
}

  .configureButton-1 {
      background-color: #81ee84;
  }

  .configureButton-2 {
      background-color: #f94c4c;
  }

  .removeButton {
      background: none;
      border: none;
      color: #f94c4c;
      cursor: pointer;
      font-size: calc(14px + 0.6vh);;
  }

  .addButton {
    background: none;
    border: none;
    /* background-image: linear-gradient(#81ee84, white); */
    color: #81ee84;
    cursor: pointer;
    font-size: calc(14px + 0.6vh);;
}

.overlayTimes1 {
    width: 17vw;
    height: 17vh;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: auto;
    border: 0.5vw;
    border-style: solid;
    border-radius: 12px;
  }

.title {
    align-items: center;
    color: black;
}