.VideoPlayer {
  text-align: center;
}

body {
  zoom: 100%;
}

.VideoPlayer-content {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.VideoPlayer-hint {
  font-size: calc(10px + 0.6vh);
  color: black;
}

.VideoPlayer-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}

.VideoPlayer-button {
  border: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  color: black;
  width: calc(120px + 10vw);
  height: calc(12px + 10vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  transition: transform 0.5s box-shadow 0.5s;
}

.VideoPlayer-button3 {
  animation-name: fadeIn;
  border: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-play-state: running;
  background-color: #3973ac;
  color: whitesmoke;
}

@keyframes pulse {
  0% {
    transform: scale(1.0);
  }

  50% {
    transform: scale(1.1);
  }
  
  100% {
    transform: scale(1.0);
  }
}

.invisible {
  display: none;
}

.show {
  display: block;
  animation-name: in-out;
  animation-duration: 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  
  100% {
    opacity: 100%;
  }
}

@keyframes in-out {
    0% {
        transform: translateY(5vw);
    }

    25% {
       transform: translateY(0vw);
    }

    95% {
      transform: translateY(0vw);
    }
}

.VideoPlayer-button1 {background-color: #81ee84;} /* Green */
.VideoPlayer-button1:active {background-color: #59a55c; transform: translateY(2px);
  box-shadow: 0px 2px 3px 0px grey;} /* Green */

.VideoPlayer-button2 {background-color: #ff7070;} /* Red */
.VideoPlayer-button2:active {background-color: #ac4c4c; transform: translateY(2px);
  box-shadow: 0px 2px 3px 0px grey;} /* Red */