.DashboardMain-Main {
    background-color: lightgray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}


.DashboardMain-Nav {
    width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  align-items: center;
  justify-content: center;
  color: crimson;
}

.DashboardMain-VideoQuickView {
    width: 20vw;
    height: 30vh;
    max-width: 100vw;
    max-height: 56.25vw;
}

.DashboardMain-Button {
    border: none;
  border-radius: 12px;
  color: black;
  width: calc(120px + 10vw);
  height: calc(6px + 6vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  background-color: red;
}

.DashboardMain-Body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; 
    gap: 155px;
    margin-top: 7.5vh;
}

.DashboardMain-Content-Video {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; 
}

.DashboardMain-Content-Survey {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px; 
}