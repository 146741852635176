body {
  zoom: 100% !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-content {
  background-color: lightgray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-navBar {
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
}

.App-video {
  width: 115.555556vh;
  height: 65vh;
  max-width: 100vw;
  max-height: 56.25vw;
}

.App-hint {
  font-size: calc(10px + 0.6vh);
  color: black;
}

.App-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 2vw;
  gap: 2vw;
}

.App-button {
  border: none;
  border-radius: 12px;
  color: black;
  width: calc(120px + 10vw);
  height: calc(12px + 10vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
}

.App-button1 {background-color: #81ee84;} /* Green */
.App-button1:active {background-color: #59a55c;} /* Green */

.App-button2 {background-color: #ff7070;} /* Red */
.App-button2:active {background-color: #ac4c4c;} /* Red */
.Default {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

html {
  scroll-behavior: smooth;
}

body {
  zoom: 100%;
}

.DemoBar {
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: whitesmoke;
}

.DemoBar-Button {
  border: none;
  border-radius: 12px;
  color: black;
  width: calc(12px + 10vw);
  height: calc(2px + 6vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 3vh;
  cursor: pointer;
  background-color: rgb(174, 216, 255);
}
.VideoPlayer-video1 {
  border-radius: 5%;
  width: 115.555556vh;
  height: 65vh;
  max-width: 100vw;
  max-height: 56.25vw;
}

.DashboardData-VideoQuickView {
  width: 10vw;
  height: 10vh;
  max-width: 100vw;
  max-height: 56.25vw;
}

.VideoConfigure-Content {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  display: flex;
  width: 80vw;
  grid-gap: 1.5vw;
  gap: 1.5vw;
  flex-direction: row;
  align-items: center;
  justify-content: top;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;
}

.configureButton {
  align-items: center;
  margin-left: 5.5vw;
  border: 1px solid black;
  border-radius: 12px;
  color: black;
  width: calc(20px + 5vw);
  height: calc(12px + 3vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(8px + 0.6vh);
  cursor: pointer;
}
/* .overlay-2 {
  align-items: flex-end;
  justify-content: space-evenly;
  justify-items: center;
  opacity: 90%;
  height: 90vh;
  width: 20vw;
  z-index: 1;
  background-image: linear-gradient(rgb(73, 71, 71), grey, lightgray); 
  background-color: rgb(73, 71, 71);   
} */

.VideoPlayer {
  text-align: center;
}

body {
  zoom: 100%;
}

.VideoPlayer-content {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.VideoPlayer-hint {
  font-size: calc(10px + 0.6vh);
  color: black;
}

.VideoPlayer-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 2vw;
  gap: 2vw;
}

.VideoPlayer-button {
  border: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  color: black;
  width: calc(120px + 10vw);
  height: calc(12px + 10vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  transition: -webkit-transform 0.5s box-shadow 0.5s;
  transition: transform 0.5s box-shadow 0.5s;
  transition: transform 0.5s box-shadow 0.5s, -webkit-transform 0.5s box-shadow 0.5s;
}

.VideoPlayer-button3 {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  border: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  background-color: #3973ac;
  color: whitesmoke;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }

  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  100% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }

  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  100% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}

.invisible {
  display: none;
}

.show {
  display: block;
  -webkit-animation-name: in-out;
          animation-name: in-out;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  
  100% {
    opacity: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  
  100% {
    opacity: 100%;
  }
}

@-webkit-keyframes in-out {
    0% {
        -webkit-transform: translateY(5vw);
                transform: translateY(5vw);
    }

    25% {
       -webkit-transform: translateY(0vw);
               transform: translateY(0vw);
    }

    95% {
      -webkit-transform: translateY(0vw);
              transform: translateY(0vw);
    }
}

@keyframes in-out {
    0% {
        -webkit-transform: translateY(5vw);
                transform: translateY(5vw);
    }

    25% {
       -webkit-transform: translateY(0vw);
               transform: translateY(0vw);
    }

    95% {
      -webkit-transform: translateY(0vw);
              transform: translateY(0vw);
    }
}

.VideoPlayer-button1 {background-color: #81ee84;} /* Green */
.VideoPlayer-button1:active {background-color: #59a55c; -webkit-transform: translateY(2px); transform: translateY(2px);
  box-shadow: 0px 2px 3px 0px grey;} /* Green */

.VideoPlayer-button2 {background-color: #ff7070;} /* Red */
.VideoPlayer-button2:active {background-color: #ac4c4c; -webkit-transform: translateY(2px); transform: translateY(2px);
  box-shadow: 0px 2px 3px 0px grey;} /* Red */
.invisible {
    display: none;
}

.confirmation-bar {
    padding: 1vw;
    border-radius: 6px;
    transition: all 0.3s;
}

.sensitive {
    background-color: rgba(129, 238, 133, 0.416);
    color: green;
}

.insensitive {
    background-color: rgba(255, 112, 112, 0.416);
    color: red;
}

/* @keyframes in-out {
    0% {
        transform: translateY(5vw);
    }

    50% {
       transform: translateY(0vw);
    }

    100% {
        opacity: 0%;
    }
} */
.DashboardMain-Main {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: top;
}

.DashboardData-TopNav {
  display: grid;
  width: 100vw;
  grid-template-columns: auto auto auto;
  place-items: center;
  background-color: whitesmoke;
  color: #3973ac;
}

.DashboardData-Buttons {
  border: none;
  /* border-style: ridge; */
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
  border-radius: 12px;
  color: whitesmoke;
  width: calc(50px + 10vw);
  height: calc(14px + 2vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  background-color: #3973ac;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.DashboardData-Buttons:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.shadow {
  -webkit-filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
          filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}

.DashboardMain-Main {
    background-color: lightgray;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: top;
}


.DashboardData-Nav {
  width: 70vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  align-items: center;
  justify-content: space-around;
  color: crimson;
}

.eNavR{
  width: 20vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  align-items: center;
  justify-content: space-around;
  color: crimson;
}

.eNavL{
  width: 10vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  align-items: center;
  color: crimson;
}

.DashboardMain-VideoQuickView {
    width: 20vw;
    height: 10vh;
    max-width: 100vw;
    max-height: 56.25vw;
}

.DashboardData-Button {
    border: none;
  border-radius: 12px;
  color: black;
  width: calc(50px + 10vw);
  height: calc(8px + 2vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  background-color: red;
}

 .DashboardData-Body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
} 

.DashboardData-eNav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.DashboardData-Body-Nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  grid-gap: 2vw;
  gap: 2vw; 
  margin-left: 10vw;
}


.Dashboard {
    align-self: flex-start;
}

.DashboardMain-Content-Video {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; 
}


li {
    display: inline;
}

td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

.DashboardData-Table {
  border: 2px solid black;
  border-style: collapse;
  width: 80vw;
}

.align {
  align-self: baseline;
  margin-left: 2vw;
}

.align2{
  margin-left: 10vw;
}

.DashboardMain-Main {
    background-color: lightgray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}


.DashboardMain-Nav {
    width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  align-items: center;
  justify-content: center;
  color: crimson;
}

.DashboardMain-VideoQuickView {
    width: 20vw;
    height: 30vh;
    max-width: 100vw;
    max-height: 56.25vw;
}

.DashboardMain-Button {
    border: none;
  border-radius: 12px;
  color: black;
  width: calc(120px + 10vw);
  height: calc(6px + 6vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  background-color: red;
}

.DashboardMain-Body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; 
    grid-gap: 155px; 
    gap: 155px;
    margin-top: 7.5vh;
}

.DashboardMain-Content-Video {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; 
}

.DashboardMain-Content-Survey {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px; 
}
tr:nth-child(even) {
    background-color: #f2f2f2;
}

table {
    border: 1.1px solid;
    border-bottom: 1.1px solid;
    margin-top: 20px;
    background-color: white;
}

.sv_q {
    background-color: white;
    /* border-bottom: 1.5px solid; */
    border-style: 1.5px dotted;
}

.surveyDiv{
    word-wrap: break-word;
    font-weight: bold;
}


.titleHeader {
    text-align: center; 
    font-size: 25px;
}

.nameHeader{
    text-align: center; 
    font-size: 25px;
}

p { margin:0 }
.Survey-Main {
  zoom: 100% !important;
    background-color: lightgray;
    background-image: linear-gradient(#9fbfdf, white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}


.Survey-Nav {
    width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  align-items: center;
  justify-content: center;
  color: crimson;
}

.DashboardMain-VideoQuickView {
    width: 20vw;
    height: 30vh;
    max-width: 100vw;
    max-height: 56.25vw;
}

.Consent-Button {
  box-shadow: 0px 4px 3px 0px grey;
  border: none;
  border-radius: 12px;
  color: white;
  width: calc(96px + 2vw);
  height: calc(2px + 6vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  background-color: #3973ac;
  transition: all 0.2s;
}

.Consent-Button:focus {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
  box-shadow: 0px 2px 3px 0px grey;
}

.Survey-Body {
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    grid-gap: 155px; 
    gap: 155px;
    margin-top: 75px;
}

.Consent-Body{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.Consent-Content{
    overflow-y: scroll;
    border-radius: 6px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content:flex-start; 
    background-color: aliceblue;
    width: 80vw;
    /* height: 100vh; */
    border: 10px;
    padding: 16px;
    border-color: red;
    overflow-y: auto;
    word-break: break-word;
}

.DashboardMain-Content-Survey {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px; 
}

.divider-line {
  height: 1vh;
  width: 10vw;
  background-color: #3973ac;
  border-radius: 6px;
}

.Survey-Survey {
  height: "80vh";
  width: "60vw";
  max-width: 100vw;
  max-height: 56.25vw;
}

.instruction-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vw;
}

@media  screen and (max-width: 500px) {
  .Consent-Button {
    width: auto
  }

  .divider-line {
    width: 20vw;
  }

  .second-instruction {
    margin-top: 3vw;
  }
}
.DashboardLogin-Entry {
    width: 100vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    color: #3973ac;
    border: black;
    border-top-style: solid;
    border-bottom-style: solid;
}

.DashboardLogin-Body {
    display: block;
    text-align: center;
    background-image: linear-gradient(#9fbfdf, white);
}

.DashboardLogin-Credentials {
    padding-top: 60px;
}

input[name="email"], input[name="password"] {
    width: 40%;
    padding: 5px 4px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: lightgrey;
    margin-top: 10px;
}

.DashboardLogin-Username {
    margin-left: 30%;
    text-align: left;
}

.DashboardLogin-Password {
    margin-left: 30%;
    text-align: left;
}

.submitLogin {
    border-radius: 12px;
    color: white;
    background-color: #3973ac;
    border: none;
    padding: 8px 60px;
    font-size: 14px;
    margin: 20px 2px;
    cursor: pointer;
}

.submitLogin:active {
    position: relative;
    top: 1px;
}

.alert {
    top: -10px;
    width: 35%;
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    display: inline-block;
}
.configureButton {
    border: none;
    align-items: center;
    margin-left: 5.5vw;
    margin-bottom: 0.5vw;
    -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
    border-radius: 12px;
    color: black;
    width: calc(20px + 5vw);
    height: calc(12px + 3vh);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: calc(8px + 0.6vh);
    cursor: pointer;
}

.configureList {
    border: none;
}

.configureHR {
    width: 15vw;
}

  .configureButton-1 {
      background-color: #81ee84;
  }

  .configureButton-2 {
      background-color: #f94c4c;
  }

  .removeButton {
      background: none;
      border: none;
      color: #f94c4c;
      cursor: pointer;
      font-size: calc(14px + 0.6vh);;
  }

  .addButton {
    background: none;
    border: none;
    /* background-image: linear-gradient(#81ee84, white); */
    color: #81ee84;
    cursor: pointer;
    font-size: calc(14px + 0.6vh);;
}

.overlayTimes1 {
    width: 17vw;
    height: 17vh;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: auto;
    border: 0.5vw;
    border-style: solid;
    border-radius: 12px;
  }

.title {
    align-items: center;
    color: black;
}
.VideoConfigure-contentL {
  /* background-color: rgb(73, 71, 71); */
  background-image: linear-gradient(rgb(73, 71, 71), grey, lightgray); 
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: calc(5px + 2vmin);
}


.VideoConfigure-content {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.VideoPlayer-video {
  width: 115.555556vh;
  height: 65vh;
  max-width: 65vw;
  max-height: 56.25vw;
}

.overlay {
  /* opacity: 90%;   */
  height: 90vh;
  width: 20vw;
  background-image: linear-gradient(rgb(73, 71, 71), grey, lightgray); 
  /* background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0, 0.9);  */
  overflow-y: auto;
}

hr {
  width: 15vw;
}

.overlayTimes1 {
  width: 17vw;
  height: 30vh;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: auto;
  border: 0.5vw;
  border-style: solid;
}

.overlayTimes2 {
  width: 17vw;
  height: 30vh;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: auto;
  border: 0.5vw;
  border-style: solid;
}

.configureBackground {
  background-image: linear-gradient(#9fbfdf, white);
}

.overlay-2 {
  display: flex;
  justify-content: unset;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  /* opacity: 90%; */
  height: 90vh;
  width: 15vw;
  background-image: linear-gradient(rgb(73, 71, 71), grey, lightgray); 
  /* background-color: rgb(73, 71, 71);    */
}

.Title {
  color: white;
}

.pushItems-Button {
  border: none;
  /* border-top: 0.5px;
  border-left: 0.5px; */
  /* border-style: inset; */
  /* border-style: ridge; */
  border-radius: 12px;
  color: white;
  /* background-image: linear-gradient(white, #ff3333); */
  background-color: #3973ac;
  width: calc(20px + 5vw);
  height: calc(12px + 3vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(8px + 0.6vh);
  cursor: pointer;
}

.pushItems-Button2 {
  border: none;
  align-items: center;
  border-radius: 12px;
  color: white;
  background-color: #3973ac;
  /* background-image: linear-gradient(white, #ff3333); */
  width: calc(20px + 5vw);
  height: calc(12px + 3vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(8px + 0.6vh);
  cursor: pointer;
}

.buttonShadow {
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
}

.videoTitle {
  -webkit-animation-name: videoSwitch;
          animation-name: videoSwitch;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
/*
@keyframes videoSwitch {
  0% {
    opacity: 100%;
  }

  50% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}
*/


.row_connor {
    display: flex;
    border-bottom: 1px solid #e7e7e7;
}

.row_connor_panel {
    background-color: white !important;
    margin-bottom: -19px;
}

.row_connor:nth-child(11) {
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
}

.error_connor {
    margin: 1em 0 !important;
    padding: 1em !important;
    border: 1px solid #ed5565;
    background-color: #fcdfe2;
    color: #ed5565;
}
.SurveyComplete-head {
    text-align: center;
    color: black;
}

.Survey-Main {
    padding-left: 20vw;
    padding-right: 20vw;
}

.divider-line {
    height: 1vh;
    width: 10vw;
    background-color: #3973ac;
    border-radius: 6px;
}

.SurveyComplete-body {
    text-align: center;
    color: black;
}

.surveyButton {
    border: none;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    color: black;
    width: calc(96px + 10vw);
    height: calc(2px + 6vh);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: calc(16px + 0.6vh);
    margin: 1vh;
    cursor: pointer;
    color: white;
    background-color: #3973ac;
}

@media screen and (max-width: 500px) {
    .surveyButton {
        height: calc(6px + 6vh);;
    }

    .divider-line {
        width: 20vw;
    }
}

