.VideoPlayer-video1 {
  border-radius: 5%;
  width: 115.555556vh;
  height: 65vh;
  max-width: 100vw;
  max-height: 56.25vw;
}

.DashboardData-VideoQuickView {
  width: 10vw;
  height: 10vh;
  max-width: 100vw;
  max-height: 56.25vw;
}

.VideoConfigure-Content {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  display: flex;
  width: 80vw;
  gap: 1.5vw;
  flex-direction: row;
  align-items: center;
  justify-content: top;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;
}

.configureButton {
  align-items: center;
  margin-left: 5.5vw;
  border: 1px solid black;
  border-radius: 12px;
  color: black;
  width: calc(20px + 5vw);
  height: calc(12px + 3vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(8px + 0.6vh);
  cursor: pointer;
}
/* .overlay-2 {
  align-items: flex-end;
  justify-content: space-evenly;
  justify-items: center;
  opacity: 90%;
  height: 90vh;
  width: 20vw;
  z-index: 1;
  background-image: linear-gradient(rgb(73, 71, 71), grey, lightgray); 
  background-color: rgb(73, 71, 71);   
} */
