.Survey-Main {
  zoom: 100% !important;
    background-color: lightgray;
    background-image: linear-gradient(#9fbfdf, white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}


.Survey-Nav {
    width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  align-items: center;
  justify-content: center;
  color: crimson;
}

.DashboardMain-VideoQuickView {
    width: 20vw;
    height: 30vh;
    max-width: 100vw;
    max-height: 56.25vw;
}

.Consent-Button {
  box-shadow: 0px 4px 3px 0px grey;
  border: none;
  border-radius: 12px;
  color: white;
  width: calc(96px + 2vw);
  height: calc(2px + 6vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  background-color: #3973ac;
  transition: all 0.2s;
}

.Consent-Button:focus {
  transform: translateY(2px);
  box-shadow: 0px 2px 3px 0px grey;
}

.Survey-Body {
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    gap: 155px;
    margin-top: 75px;
}

.Consent-Body{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.Consent-Content{
    overflow-y: scroll;
    border-radius: 6px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content:flex-start; 
    background-color: aliceblue;
    width: 80vw;
    /* height: 100vh; */
    border: 10px;
    padding: 16px;
    border-color: red;
    overflow-y: auto;
    word-break: break-word;
}

.DashboardMain-Content-Survey {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px; 
}

.divider-line {
  height: 1vh;
  width: 10vw;
  background-color: #3973ac;
  border-radius: 6px;
}

.Survey-Survey {
  height: "80vh";
  width: "60vw";
  max-width: 100vw;
  max-height: 56.25vw;
}

.instruction-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vw;
}

@media  screen and (max-width: 500px) {
  .Consent-Button {
    width: auto
  }

  .divider-line {
    width: 20vw;
  }

  .second-instruction {
    margin-top: 3vw;
  }
}