.invisible {
    display: none;
}

.confirmation-bar {
    padding: 1vw;
    border-radius: 6px;
    transition: all 0.3s;
}

.sensitive {
    background-color: rgba(129, 238, 133, 0.416);
    color: green;
}

.insensitive {
    background-color: rgba(255, 112, 112, 0.416);
    color: red;
}

/* @keyframes in-out {
    0% {
        transform: translateY(5vw);
    }

    50% {
       transform: translateY(0vw);
    }

    100% {
        opacity: 0%;
    }
} */