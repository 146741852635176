.row_connor {
    display: flex;
    border-bottom: 1px solid #e7e7e7;
}

.row_connor_panel {
    background-color: white !important;
    margin-bottom: -19px;
}

.row_connor:nth-child(11) {
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
}

.error_connor {
    margin: 1em 0 !important;
    padding: 1em !important;
    border: 1px solid #ed5565;
    background-color: #fcdfe2;
    color: #ed5565;
}