.VideoConfigure-contentL {
  /* background-color: rgb(73, 71, 71); */
  background-image: linear-gradient(rgb(73, 71, 71), grey, lightgray); 
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: calc(5px + 2vmin);
}


.VideoConfigure-content {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.VideoPlayer-video {
  width: 115.555556vh;
  height: 65vh;
  max-width: 65vw;
  max-height: 56.25vw;
}

.overlay {
  /* opacity: 90%;   */
  height: 90vh;
  width: 20vw;
  background-image: linear-gradient(rgb(73, 71, 71), grey, lightgray); 
  /* background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0, 0.9);  */
  overflow-y: auto;
}

hr {
  width: 15vw;
}

.overlayTimes1 {
  width: 17vw;
  height: 30vh;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: auto;
  border: 0.5vw;
  border-style: solid;
}

.overlayTimes2 {
  width: 17vw;
  height: 30vh;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: auto;
  border: 0.5vw;
  border-style: solid;
}

.configureBackground {
  background-image: linear-gradient(#9fbfdf, white);
}

.overlay-2 {
  display: flex;
  justify-content: unset;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  /* opacity: 90%; */
  height: 90vh;
  width: 15vw;
  background-image: linear-gradient(rgb(73, 71, 71), grey, lightgray); 
  /* background-color: rgb(73, 71, 71);    */
}

.Title {
  color: white;
}

.pushItems-Button {
  border: none;
  /* border-top: 0.5px;
  border-left: 0.5px; */
  /* border-style: inset; */
  /* border-style: ridge; */
  border-radius: 12px;
  color: white;
  /* background-image: linear-gradient(white, #ff3333); */
  background-color: #3973ac;
  width: calc(20px + 5vw);
  height: calc(12px + 3vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(8px + 0.6vh);
  cursor: pointer;
}

.pushItems-Button2 {
  border: none;
  align-items: center;
  border-radius: 12px;
  color: white;
  background-color: #3973ac;
  /* background-image: linear-gradient(white, #ff3333); */
  width: calc(20px + 5vw);
  height: calc(12px + 3vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(8px + 0.6vh);
  cursor: pointer;
}

.buttonShadow {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
}

.videoTitle {
  animation-name: videoSwitch;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
/*
@keyframes videoSwitch {
  0% {
    opacity: 100%;
  }

  50% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}
*/

