.SurveyComplete-head {
    text-align: center;
    color: black;
}

.Survey-Main {
    padding-left: 20vw;
    padding-right: 20vw;
}

.divider-line {
    height: 1vh;
    width: 10vw;
    background-color: #3973ac;
    border-radius: 6px;
}

.SurveyComplete-body {
    text-align: center;
    color: black;
}

.surveyButton {
    border: none;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    color: black;
    width: calc(96px + 10vw);
    height: calc(2px + 6vh);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: calc(16px + 0.6vh);
    margin: 1vh;
    cursor: pointer;
    color: white;
    background-color: #3973ac;
}

@media screen and (max-width: 500px) {
    .surveyButton {
        height: calc(6px + 6vh);;
    }

    .divider-line {
        width: 20vw;
    }
}