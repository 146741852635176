.DashboardMain-Main {
    background-color: lightgray;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: top;
}


.DashboardData-Nav {
  width: 70vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  align-items: center;
  justify-content: space-around;
  color: crimson;
}

.eNavR{
  width: 20vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  align-items: center;
  justify-content: space-around;
  color: crimson;
}

.eNavL{
  width: 10vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  align-items: center;
  color: crimson;
}

.DashboardMain-VideoQuickView {
    width: 20vw;
    height: 10vh;
    max-width: 100vw;
    max-height: 56.25vw;
}

.DashboardData-Button {
    border: none;
  border-radius: 12px;
  color: black;
  width: calc(50px + 10vw);
  height: calc(8px + 2vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  background-color: red;
}

 .DashboardData-Body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
} 

.DashboardData-eNav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.DashboardData-Body-Nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2vw; 
  margin-left: 10vw;
}


.Dashboard {
    align-self: flex-start;
}

.DashboardMain-Content-Video {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; 
}


li {
    display: inline;
}

td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

.DashboardData-Table {
  border: 2px solid black;
  border-style: collapse;
  width: 80vw;
}

.align {
  align-self: baseline;
  margin-left: 2vw;
}

.align2{
  margin-left: 10vw;
}
