.DashboardMain-Main {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: top;
}

.DashboardData-TopNav {
  display: grid;
  width: 100vw;
  grid-template-columns: auto auto auto;
  place-items: center;
  background-color: whitesmoke;
  color: #3973ac;
}

.DashboardData-Buttons {
  border: none;
  /* border-style: ridge; */
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
  border-radius: 12px;
  color: whitesmoke;
  width: calc(50px + 10vw);
  height: calc(14px + 2vh);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(16px + 0.6vh);
  margin: 1vh;
  cursor: pointer;
  background-color: #3973ac;
  transition: transform 0.3s;
}

.DashboardData-Buttons:hover {
  transform: scale(1.1);
}

.shadow {
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
