.Default {
  /* background-color: lightgray; */
  background-image: linear-gradient(#9fbfdf, white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

html {
  scroll-behavior: smooth;
}

body {
  zoom: 100%;
}
